<template>
  <v-container>
    <v-layout row wrap justify-end>
      <v-flex xs12 sm12 md4 lg4 class="text-right">
        <v-text-field
          v-model="search"
          dense
          solo
          :placeholder="$t('search owner')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :no-data-text="$t('no client available')"
          :items="filterOwners"
          :items-per-page="20"
        >
          <template v-slot:[`item.avatar`]="{ item }">
            <v-avatar size="35">
              <v-img :src="item.avatar">
                <template v-slot:placeholder>
                  <v-row justify="center" align="center">
                    <v-progress-circular
                      width="3"
                      :color="$store.state.primaryColor"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </template>

          <template v-slot:[`item.names`]="{ item }">
            {{ getFullnames(item.names) }}
          </template>

          <template v-slot:[`item.gender`]="{ item }">
            <v-row justify="center">
              <v-icon v-if="item.gender == 'M'" color="primary darken-3"
                >fas fa-male</v-icon
              >
              <v-icon v-else color="pink darken-3">fas fa-female</v-icon>
            </v-row>
          </template>

          <template v-slot:[`item.dateCreated`]="{ item }">
            <span class="">{{ DateFormat(item.dateCreated) }}</span>
          </template>

          <template v-slot:[`item.accessRight`]="{ item }">
            <v-switch
              v-model="item.accessRight"
              :color="$store.state.secondaryColor"
              @change="accessRight(item)"
            ></v-switch>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-icon v-if="item.status" color="success darken-3"
              >fas fa-check-circle</v-icon
            >
            <v-icon v-else color="error darken-3">fas fa-times-circle</v-icon>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn small color="yellow" @click="configData(item.id)"
              >Data <v-icon right small>mdi-cog-transfer-outline</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">{{ $t("delete client") }} </v-card-title>
        <v-card-text class="pa-4">
          {{ $t("are you sure you want to delete client") }}
          <span class="light-blue--text"
            >{{ client.name + ": " + client.id }}
          </span>
          {{ $t("from the system") }} ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogDelete = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            :loading="loadingDelete"
            @click="deleteOwner()"
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ################## -->

    <v-snackbar v-model="snackSuccess" dark top center timeout="4000">
      <span>{{ $t(message) }}</span>
      <template v-slot:action="{ attr }">
        <v-btn
          v-bind="attr"
          icon
          :color="$store.state.secondaryColor"
          @click="snackSuccess = false"
          ><v-icon>close</v-icon></v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import format from "date-fns/format";
export default {
  data: () => ({
    owners: [],
    businesses: [],
    loading: true,
    loadingDelete: false,
    dialogDelete: false,
    snackSuccess: false,

    search: "",

    icons: [
      {
        icon: "visibility",
        route: "profile",
        color: "#038cbe",
      },
      { icon: "edit", route: "edit", color: "#ef6c00" },
      { icon: "delete", route: "delete", color: "red" },
    ],

    message: "",

    client: {
      id: "",
      name: "",
    },
  }),

  computed: {
    filterOwners() {
      return this.owners.filter((val) => {
        return (
          val.names.first.toLowerCase().includes(this.search.toLowerCase()) ||
          val.names.middle.toLowerCase().includes(this.search.toLowerCase()) ||
          val.names.last.toLowerCase().includes(this.search.toLowerCase()) ||
          val.email.toLowerCase().includes(this.search.toLowerCase()) ||
          val.gender.toLowerCase().includes(this.search.toLowerCase()) ||
          val.phone.toLowerCase().includes(this.search.toLowerCase()) ||
          this.DateFormat(val.dateCreated).includes(this.search)
        );
      });
    },
    headers() {
      return [
        {
          text: "#",
          value: "avatar",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("names"),
          value: "names",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("gender"),
          value: "gender",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("email"),
          value: "email",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("access right"),
          value: "accessRight",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("is logged in"),
          value: "status",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("date created"),
          value: "dateCreated",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("action"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
      ];
    },
  },

  created() {
    this.getOwners();
  },

  methods: {
    async getOwners() {
      this.owners = [];
      const snapshot = await db.collection("owners").get();

      snapshot.forEach((snaps) => {
        db.collection("users")
          .where("username", "==", snaps.id)
          .get()
          .then((response) => {
            response.forEach((element) => {
              this.owners.push({
                id: snaps.id,
                userId: element.id,
                status: element.data().status,
                accessRight: element.data().accessRight,
                ...snaps.data(),
              });
            });
          });
        this.loading = false;
      });
    },

    DateFormat(data) {
      var date = data.toDate();
      return format(date, "EEE, dd MMMM, yyyy hh:mm");
    },

    getFullnames(data) {
      return data.first + " " + data.middle.substr(0, 1) + ". " + data.last;
    },

    accessRight(data) {
      db.collection("users")
        .doc(data.userId)
        .update({
          accessRight: data.accessRight,
        })
        .then(() => {
          this.message = "Access Right updated successfully";
          this.snackSuccess = true;
        });
    },

    goTo(route, data) {
      if (route == "delete") {
        this.client.name = this.getFullnames(data.names);
        this.client.id = data.id;
        this.dialogDelete = true;
      } else {
        this.$router.push({ name: "profileOwner", params: { id: data.id } });
      }
    },

    deleteOwner() {
      //first delete owner
      if (this.client.id != "") {
        this.loadingDelete = true;
        db.collection("owners")
          .doc(this.client.id)
          .delete()
          .then(() => {
            //if successful, delete business
            this.deleteOwnersBusinesses();
          });
      }
    },
    deleteOwnersBusinesses() {
      if (this.client.id != "") {
        this.loadingDelete = true;
        db.collection("businesses")
          .where("ownerId", "==", this.client.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.businesses.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            // ##############
            //check if client had businesses. so that we also delete them
            this.loadingDelete = true;
            if (this.businesses.length >= 1) {
              for (let i = 0; this.businesses.length; i++) {
                db.collection("businesses")
                  .doc(this.businesses[i].id)
                  .delete()
                  .then(() => {
                    this.deleteLicenses();
                  });
              }
            } else {
              this.deleteLicenses();
            }
            // ##############
          });
      }
      // snapshot.forEach((snaps) => {
      //   db.collection("users")
      //     .where("username", "==", snaps.id)
      //     .get()
      //     .then((response) => {
      //       response.forEach((element) => {
      //         this.owners.push({
      //           id: snaps.id,
      //           userId: element.id,
      //           status: element.data().status,
      //           accessRight: element.data().accessRight,
      //           ...snaps.data(),
      //         });
      //       });
      //     });
      //   this.loading = false;
      // });
    },
    deleteLicenses() {
      if (this.client.id != "") {
        db.collection("licenses")
          .where("ownerId", "==", this.client.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data()) {
                this.loadingDelete = true;
                db.collection("licenses")
                  .doc(doc.id)
                  .delete()
                  .then(() => {
                    this.message = "client deleted successfully";
                    this.loadingDelete = false;
                    this.dialogDelete = false;
                    this.snackSuccess = true;

                    this.getOwners();
                  });
              }
            });
          });
      }
    },
    configData(ownerId) {
      this.$router.push({
        name: "ConfigurationsData",
        params: { ownerId: ownerId },
      });
    },
  },
};
</script>
